var activeSlug;
var activeCategory;
var isScrolling = false, // gdy strona się scrolluje to zmienna ma wartość true i nie wysyłamy statów na hover np. akordeon, hotpoint
  lastReportedSlug = null,
  wp_content_selector,
  customOffset = $('.header').outerHeight();

window.addEventListener('resize', (event) => {
  customOffset = $('.header').outerHeight();
});

(function () {
  if (
    !document.getElementsByClassName('__js_stats_wrapper').length ||
    document.location.href.indexOf('.html') > 0
  )
    return;

  $sections = $('.__js_stats_wrapper');
  var initSlug =
    document.location.pathname.split('/').pop() ||
    $('.__js_stats_wrapper').eq(0).attr('id');

  activeSlug = initSlug;
  activeCategory = $('.__js_stats_wrapper[id=' + initSlug + ']').attr(
    'data-category'
  );

  var animationTime = 800,
    // $sections = $('.section__wrapper'),
    /* Przewijanie do sekcji slug jeżeli w URL jest ustawiony jakiś slug */
    scrollOnStart = function () {

      if (isPostLogin) return;
      
      if ($('#' + initSlug).length) {
        $('html,body').animate(
          {
            scrollTop: $('#' + initSlug).offset().top - customOffset,
          },
          animationTime,
          'easeOutExpo'
        );
      }

      sendStats('progress', 'start', initSlug);

      if (document.location.pathname.split('/').pop().length < 1) {
        changeActiveSlug(initSlug);
      } else {
        if (lastReportedSlug === null) {
          reportSlug();
        }
      }

      $('.header__nav [data-slug]').removeClass('__active');
      $('.header__nav [data-slug=' + activeSlug + ']').addClass('__active');

      $('.header__nav [data-category]').removeClass('__active');
      $('.header__nav [data-category=' + activeCategory + ']').addClass(
        '__active'
      );

      initNavigation();
    },
    // staty pageviews i scrollboost pwp ----- {

    reportSlugTS = -1,
    reportSlug = function () {
      if (lastReportedSlug === null) {
        //wyślij statystykę pageview standard
        // console.log("statData[activeSlug]", statData);

        wp_dot_addparams.ctags = statData[activeSlug].tags;
        WP.push(function () {
          WP.pwp.viewPage({
            ga: {
              event: 'pageview',
              page: {
                pageviewType: 'standard',
                error404: basicStatData.ga.page.error404,
                templateType: 'AS',
              },
              site: {
                name: basicStatData.ga.site.name,
                version: basicStatData.ga.site.version,
                cms: basicStatData.ga.site.cms,
                as: {
                  orderId: asorderid,
                  productName: asproduct,
                  name: asname,
                },
              },
              content: {
                title: statData[activeSlug].title,
                category: {
                  IAB: statData[activeSlug].category.IAB,
                  Gemius: statData[activeSlug].category.Gemius,
                },
                tags: statData[activeSlug].tags,
              },
            },
          });
        });
      } else {
        // wyślij statystykę pageview scrolboost vpv. pageviewType ma wartość "overlay"
        // gdy nie mamy doładowania i "scrollboost" gdy następuje doładowanie, ALE dla pseudo dynamicznych stron AS ma byc jednak scrollboost ;) - ustalone z Salestube !

        wp_content_selector = document.getElementById(lastReportedSlug);

        unloadData(lastReportedSlug);

        wp_dot_addparams.ctags = statData[activeSlug].tags;
        WP.push(function () {
          WP.pwp.viewPage({
            ga: {
              event: 'pageviewVpv',
              page: {
                pageviewType: 'scrollboost',
                error404: basicStatData.ga.page.error404,
                templateType: 'AS',
              },
              site: {
                name: basicStatData.ga.site.name,
                version: basicStatData.ga.site.version,
                cms: basicStatData.ga.site.cms,
                as: {
                  orderId: asorderid,
                  productName: asproduct,
                  name: asname,
                },
                frontendPlatform: 'other',
              },
              content: {
                title: statData[activeSlug].title,
                category: {
                  IAB: statData[activeSlug].category.IAB,
                  Gemius: statData[activeSlug].category.Gemius,
                },
                tags: statData[activeSlug].tags,
              },
              //"content": statData[activeSlug]
            },
          });
        });
      }

      lastReportedSlug = activeSlug;
      reportSlugTS = -1;
    },
    changeActiveSlug = function (slug) {
      activeSlug = slug;
      activeCategory = $('.__js_stats_wrapper[id=' + slug + ']').attr(
        'data-category'
      );

      // if ( getActiveSlugIndex()==0 ) {
      //     slug = "/";
      //     $('.header__nav ul').attr('class', 'order_'+ 1);// == 0;
      // }

      $('.header__nav [data-slug]').removeClass('__active');
      $('.header__nav [data-slug=' + activeSlug + ']').addClass('__active');

      $('.header__nav [data-category]').removeClass('__active');
      $('.header__nav [data-category=' + activeCategory + ']').addClass(
        '__active'
      );

      if (document.location.search.length > 0) {
        history.pushState(
          {
            id: slug,
          },
          $(this).attr('title'),
          slug + document.location.search
        );
        if (document.querySelector('title')) {
          document.querySelector('title').innerText =
            statData[activeSlug].title + ' - ' + defaultSiteTitle;
        }
        if (document.querySelector('meta[property="og:title"]')) {
          document.querySelector('meta[property="og:title"]').content =
            statData[activeSlug].title + ' - ' + defaultSiteTitle;
        }
        if (document.querySelector('meta[name="twitter:title"]')) {
          document.querySelector('meta[name="twitter:title"]').content =
            statData[activeSlug].title + ' - ' + defaultSiteTitle;
        }
      } else {
        history.pushState(
          {
            id: slug,
          },
          $(this).attr('title'),
          slug
        );
        if (document.querySelector('title')) {
          document.querySelector('title').innerText =
            statData[activeSlug].title + ' - ' + defaultSiteTitle;
        }
        if (document.querySelector('meta[property="og:title"]')) {
          document.querySelector('meta[property="og:title"]').content =
            statData[activeSlug].title + ' - ' + defaultSiteTitle;
        }
        if (document.querySelector('meta[name="twitter:title"]')) {
          document.querySelector('meta[name="twitter:title"]').content =
            statData[activeSlug].title + ' - ' + defaultSiteTitle;
        }
      }

      if (lastReportedSlug === null) {
        // jeżeli pierwsze wejście to wysyłaj pageview od razu
        reportSlug();
      } else if (lastReportedSlug != slug) {
        // jeżeli zmiana sluga to wysyłaj po sekundzie statystykę

        if (reportSlugTS > -1) clearTimeout(reportSlugTS);
        reportSlugTS = setTimeout(reportSlug, 1000);
      } else {
        if (reportSlugTS > -1) clearTimeout(reportSlugTS);
      }
    },
    /* Sprawdza index sluga, domyślnie aktualnego */
    getActiveSlugIndex = function (slug) {
      if (!slug) slug = activeSlug;
      return $('.header__nav [data-slug=' + slug + ']')
        .parent()
        .parent()
        .index();
    },
    /* Sprawdzanie czy user zescrollował do sekcji. Jeżeli tak to ustawia pozycję w nawigacji */
    sectionScrollCheck = function () {
      sectionScrollCheckTS = setTimeout(sectionScrollCheck, 200);

      var $W = $(window),
        topScroll = $W.scrollTop(),
        wHeight = $W.height();

      //wymuszenie strony głównej
      if (
        topScroll <= customOffset &&
        document.location.pathname.split('/').pop().length < 1
      ) {
        changeActiveSlug($sections.eq(0).attr('id'));
        return;
      }

      for (var i = 0; i < $sections.length; i++) {
        var $section = $sections.eq(i),
          sectionOffset = $section.offset().top,
          sectionHeight = $section.height();

        //console.info(i, topScroll, sectionOffset, sectionOffset + sectionHeight)

        if (
          activeSlug != $section.attr('id') &&
          topScroll > sectionOffset - customOffset - 10 &&
          topScroll < sectionOffset + sectionHeight - customOffset - 10
        ) {
          changeActiveSlug($section.attr('id'));
          break;
        }
      }
    },
    sectionScrollCheckTS = null,
    /* Inicjalizacja metod dla obsługi nawigacji - clików i sprawdzanie scrolla */
    initNavigation = function () {
      sectionScrollCheckTS = setTimeout(sectionScrollCheck, animationTime + 25);

      $('.header__nav [data-slug]').on('click', function (e) {
        // console.log('click');

        if (sectionScrollCheckTS) clearTimeout(sectionScrollCheckTS);

        e.preventDefault();
        var $this = $(this),
          slug = $(this).data('slug');
        if (!slug.length) slug = '/';

        changeActiveSlug(slug);

        $('html,body').animate(
          {
            scrollTop:
              getActiveSlugIndex() > 0
                ? $('#' + slug).offset().top - customOffset
                : 0,
          },
          animationTime,
          'easeInOutExpo',
          function () {
            isScrolling = false;
          }
        );
        sectionScrollCheckTS = setTimeout(
          sectionScrollCheck,
          animationTime + 25
        );
      });
    };

  if (initSlug.length > 1) {
    setTimeout(scrollOnStart, 600);
  }
})();
