'use strict';

const containerWideo = $('#gala .container__wideo');
const countdownWidget = $('#gala .player__countdown');
const startDateAttr = $('#gala .container__wideo[data-start-date]').attr('data-start-date');
const startDate = new Date(startDateAttr);
const countdownText = $('#gala .player__countdown .countdown_time');
let checkTime;

function setTime() {
    const now = new Date();
    let diff = startDate - now;
    if(diff < 0) {
        containerWideo.attr('video-on', true);
        clearInterval(checkTime);
        return;
    }

    let number, unit;
    const numberSet = ['2', '3', '4'];

    //więcej niż 24h
    if(diff > 86400000) {
        number = Math.floor(diff / 86400000);
        if(number === 1) {
            unit = 'dzień';
        }
        else {
            unit = 'dni';
        }
    }
    //więcej niż 1h
    else if(diff > 3600000) {
        number = Math.floor(diff / 3600000);
        if(number === 1) {
            unit = 'godzina';
        }
        else if(jQuery.inArray(String(number)[String(number).length - 1], numberSet) != -1
                && !(number >= 12 && number <= 14)) {
                    console.log(jQuery.inArray(String(number)[String(number).length - 1], numberSet));
            unit = 'godziny';
        }
        else {
            unit = 'godzin';
        }
    }
    else {
        number = Math.floor(diff / 60000);
        if(number === 1) {
            unit = 'minuta';
        }
        else if(jQuery.inArray(String(number)[String(number).length - 1], numberSet) != -1
                && !(number >= 12 && number <= 14)) {
                    console.log(jQuery.inArray(String(number)[String(number).length - 1], numberSet));
            unit = 'minuty';
        }
        else {
            unit = 'minut';
        }
    }

    countdownText.empty();
    countdownText.append(`<span>${number}</span><span>${unit}</span`);
}

setTime();

if(startDate - new Date() > 0) {
    checkTime = setInterval(setTime, 10000);
}
