const spinnerHtml = `<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
   <circle class="path" fill="none" stroke-width="3" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
</svg>`;

const createSpinner = function (el) {
  el.querySelectorAll('input, textarea, button').forEach((el) => {
    el.setAttribute('disabled', '');
  });
  el.classList.add('form-opacity');
  const spinnerWrraper = document.createElement('div');
  spinnerWrraper.classList.add('spinner-wrapper');
  spinnerWrraper.innerHTML = spinnerHtml;
  el.append(spinnerWrraper);
};
