const setSize = function () {
  if (document.querySelector('.section__wrapper#o-plebiscycie')) {
    document
      .querySelectorAll('.section__wrapper#o-plebiscycie .wrapper')[0]
      .style.setProperty(
        '--mask-height',
        Math.ceil(
          document
            .querySelectorAll('.section__wrapper#o-plebiscycie .wrapper')[0]
            .getBoundingClientRect().height
        ) +
          100 +
          'px'
      );
  }
  if (document.querySelector('.article--ajax-wrapper')) {
    document
      .querySelectorAll('.article--ajax-wrapper')[0]
      .querySelectorAll('.article__heading')[0]
      .style.setProperty(
        '--mask-height',
        Math.ceil(
          document
            .querySelectorAll('.article--ajax-wrapper')[0]
            .querySelectorAll('.article__heading')[0]
            .getBoundingClientRect().height
        ) +
          100 +
          'px'
      );
  }
};

window.addEventListener('DOMContentLoaded', () => {
  setSize();
});
window.addEventListener('resize', (event) => {
  setSize();
});
