'use strict';

(function () {
  var MenuToggler = $('.header__hamburger').click(function (e) {
    $(this).toggleClass('__js_isActive');
    $('.header__nav').toggleClass('__js_isActive');
  });
  $('.header__nav ul').on('click', function () {
    $('.header__hamburger').removeClass('__js_isActive');
    setTimeout(function () {
      $('.header__nav').removeClass('__js_isActive');
    }, 500);
  });
  // $('.header__nav ul').on('mouseleave', function () {
  //   $('.header__nav').removeClass('__js_isActive');
  //   $('.header__hamburger').removeClass('__js_isActive');
  // });
})();

const headerWrapper = $('.header > .wrapper');

function setBkg() {
  if($(window).scrollTop() > 10) {
    headerWrapper.addClass('apply-header-bkg');
  }
  else {
    headerWrapper.removeClass('apply-header-bkg');
  }
}

setBkg();

$(document).scroll(function() {
  setBkg();
});
