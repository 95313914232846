'use strict';

let bubblesDarkCount,
    bubblesWhiteCount,
    dotsYellowCount,
    dotsBlueCount;

function prepareBkg() {
    const documentHeight = $(document).height();
    const windowHeight = $(window).height();
    const windowsInDocument = (Math.floor(documentHeight / windowHeight));

    bubblesDarkCount = windowsInDocument * 0.9;
    bubblesWhiteCount = windowsInDocument * 0.9;
    dotsYellowCount = windowsInDocument * 1.7;
    dotsBlueCount = windowsInDocument * 1.9;

    $('body').prepend(
        `<div class="outer_background">
            <div class="bubbles_dark">
            </div>
            <div class="bubbles_white">
            </div>
            <div class="dots_yellow">
            </div>
            <div class="dots_blue">
            </div>
        </div>`
    );
    
    let x = 0;

    for(let i = 0; i < bubblesDarkCount; i++) {
        if(i % 3 == 0) { x = 20; }
        else if(i % 4 == 0) { x = 50; }
        $('.outer_background .bubbles_dark').append(`<div class="bubble_dark" style="top:${(i * 70) + 1 + x}vh"></div>`);
    }
    for(let i = 0; i < bubblesWhiteCount; i++) {
        if(i % 5 == 0) { x = 45; }
        else if(i % 2 == 0) { x = 30; }
        else if(i == 1) { x = -42; }
        $('.outer_background .bubbles_white').append(`<div class="bubble_white" style="top:${(i * 100 - 40) + x}vh"></div>`);
    }
    for(let i = 0; i < dotsYellowCount; i++) {
        let y = 30;
        x = 30;
        if(i % 2 == 0) { x = 10; }
        if(i > 3) { y += 35; }
        $('.outer_background .dots_yellow').append(`<div class="dot_yellow" style="top:${(i * y) + x}vh"></div>`);
    }
    for(let i = 0; i < dotsBlueCount; i++) {
        let y = 20;
        x = 30;
        if(i % 4 == 2) { x = 45; }
        if(i > 1) { y += 30; }
        $('.outer_background .dots_blue').append(`<div class="dot_blue" style="top:${(i * y) + x}vh"></div>`);
    }
}

$(document).ready(function() {
    setTimeout(function() {  
        $('.outer_background').css('height', $('body').height() + 'px');
    }, 2000);

    prepareBkg();

    const a = document.querySelector('.outer_background .bubbles_dark'),
      b = document.querySelector('.outer_background .bubbles_white'),
      c = document.querySelector('.outer_background .dots_yellow'),
      d = document.querySelector('.outer_background .dots_blue'),
      e = document.documentElement;

    $(window).unbind("scroll").scroll(function () {
        a.style.top = (Math.max(e.scrollTop, a.scrollTop) / 2) + "px";
        b.style.top = (Math.max(e.scrollTop, b.scrollTop) / 3) + "px";
        c.style.top = (Math.max(e.scrollTop, c.scrollTop) / 4) + "px";
        d.style.top = (Math.max(e.scrollTop, d.scrollTop) / 6) + "px";
    });
});

$('[data-article-type="voting"]').each(function() {
    $(this).addClass('bkg-end');
});

$(window).on('resize', function() {
    setTimeout(function() {  
        $('.outer_background').css('height', $('body').height() + 'px');
    }, 500);
});
