//ver 1.1 GT

'use strict';
function sendStats(category, action, label, value) {
  if (value) {
    WP.push(function () {
      WP.stat.show(
        wp_sn,
        {
          category: category,
          label: label,
          value: value,
          asproduct: asproduct,
          asorderid: asorderid,
          asname: asname,
        },
        action
      );
    });

    gtag('event', action + '-' + value, {
      event_category: category,
      event_label: label,
    });
    // console.log('sendStats ' + category + ', ' +action + ", " +label + ", " + value + ", asproduct:" + asproduct + ", asorderid: " + asorderid + ", asname: " + asname);
  } else {
    WP.push(function () {
      WP.stat.show(
        wp_sn,
        {
          category: category,
          label: label,
          asproduct: asproduct,
          asorderid: asorderid,
          asname: asname,
        },
        action
      );
    });

    gtag('event', action, {
      event_category: category,
      event_label: label,
    });
    // console.log('sendStats ' + category + ', ' +action + ", " +label + ", asproduct:" + asproduct + ", asorderid: " + asorderid + ", asname: " + asname);
  }
}

var custom360Action = function (j) {
  WP.push(function () {
    WP.pwp.customAction({ ga: j });
  });
};

$(document).on(
  'click auxclick contextmenu',
  '[data-ga360-click]',
  function (e) {
    //console.log(e.which);
    //console.log(e.type);

    if (e.type == 'contextmenu') {
      return;
    }

    var T = $(this).data('ga360-click');

    switch (e.which) {
      case 1:
        //console.log('[data-ga360-click] = click');
        WP.push(function () {
          WP.pwp.customAction({ ga: T });
        });
        break;
      case 2:
        //console.log('[data-ga360-click] = weel click');
        WP.push(function () {
          WP.pwp.customAction({ ga: T });
        });
        break;
      case 3:
        return true;
    }
  }
);

var scroll100Indicator = 0;
var unloadCollector = new Array(); // { id0, viewedCount0, itemCount0 }, { id1, viewedCount1, itemCount1 }  tablica do inteaktuwności sliderowych.
var forUnloadCollector = function (id, viewedCount, itemCount) {
  var objectData = new Object();
  objectData.id = id;
  objectData.viewedCount = viewedCount;
  objectData.itemCount = itemCount;
  unloadCollector.push(objectData);
  // unloadCollector push {id, viewedCount, itemCount}
};

// unload
var indT = new Array();

var unloadData = function () {
  var ac = ac || lastReportedSlug;

  // console.log('ac: ' + ac);

  if (!ac) return;

  var index = $('#' + ac).index();
  var count = 0;
  for (var i = 0; i < indT.length; ++i) {
    if (indT[i] == index - 1) count++;
  }
  if ((count = 4)) {
    scroll100Indicator = 1;
  } else {
    scroll100Indicator = 0;
  }

  var statElementArray = [];

  for (var pos in statElementCollector) {
    statElementArray.push(statElementCollector[pos]);
  }

  var cScrollPercentValue = cScrollPercent.burp() || 0;

  DC += cScrollPercentValue + ' ';

  try {
    WP.push(function () {
      WP.pwp.closePage({
        ga: {
          event: 'unload',
          page: {
            sliderConsumption: statElementArray,
            content: { section: { scroll100Indicator: scroll100Indicator } },
            ac: ac,
            templateType: 'AS',
          },
          content: {
            pageViewCount: 1,
            scrollPercent: '',
            cScrollPercent: cScrollPercentValue,
            cS: cScrollPercentValue,
          },
        },
      });
    });
    //console.log(dataLayer);
  } catch (error) {
    console.error('błąd wpjslib: ', error);
  }
};

window.addEventListener('beforeunload', unloadData);

// debug do cScrolla
var $DEBUG = $('<div>').attr({ id: 'STAT-DEBUG' }).css({
  position: 'fixed',
  bottom: 0,
  left: 0,
  maxWidth: 500,
  maxHeight: 400,
  overflow: 'scroll',
  background: 'rgba(200,200,255,.95)',
  padding: 5,
  font: '12px Arial',
  zIndex: 9e5,
});
//.appendTo('body');

var DC = '';
setInterval(function () {
  $DEBUG.html(
    'AC: ' +
      activeSlug +
      ', lastReported: ' +
      lastReportedSlug +
      '<br>' +
      cScrollPercent.debug() +
      '<br>' +
      DC
  );
}, 250);
