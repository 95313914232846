'use strict';

$.extend($.easing, {
  easeOutExpo: function (x, t, b, c, d) {
    return t == d ? b + c : c * (-Math.pow(2, (-10 * t) / d) + 1) + b;
  },
  easeInOutExpo: function (x, t, b, c, d) {
    if (t == 0) return b;
    if (t == d) return b + c;
    if ((t /= d / 2) < 1) return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
    return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
  },
});

// zainicjowanie owli

//var owl = $('.owl-1-item');
//
//owl.owlCarousel({
//  loop: true,
//  nav: true,
//  items: 1,
//  center: true,
//  // responsiveBaseElement: $('body'),
//  responsive: {
//    0: { margin: 20 },
//    769: { margin: 30 },
//    1381: { margin: 60 },
//  },
//});


// zainicjowanie owli

var owl = $('.owl-carousel');
var owlJury = $('.owl-1-item');
var owlLogos = $('.owl-logos');
var owlLogosSection = $('.owl-logos-section');

owlJury.owlCarousel({
  loop: true,
  nav: true,
  items: 1,
  center: true,
  responsive: {
    0: { margin: 20 },
    769: { margin: 30 },
    1381: { margin: 60 },
  },
});

owlLogos.owlCarousel({
  loop: true,
  nav: false,
  items: 1,
  center: true,
  margin: 10,
  autoplay: true,
  slideTransition: 'linear',
  autoplayTimeout: 3000,
  autoplaySpeed: 3000,
  autoplayHoverPause: true,
});

owlLogosSection.owlCarousel({
  loop: true,
  nav: false,
  autoWidth: true,
  center: false,
  autoplay: true,
  slideTransition: 'linear',
  autoplayTimeout: 3000,
  autoplaySpeed: 3000,
  autoplayHoverPause: true,
  responsive: {
    0: { margin: 32, items: 1, center: true },
    340: { margin: 32, items: 1, center: true },
    769: { margin: 48, items: 1, center: true },
  },
});



//Obiekt zbierający informacje o elementach typu banner, których statystyki należy wysłać w unload
var statElementCollector = new Object();

var ga360statElementsInit = function (el) {
  alert($(el).data('ga360-id'));
};

var slidePositionVieved = new Object();
//owlstats ga360 slider stats slider

//owlstats ga360 slider stats slider
$(owl).each(function () {
  //sprawdzamy, czy jest ustawiony id dla statystyk
  var $artID = $(this).parents('.__js_stats_wrapper').data('id');
  var ga360id = $(this).data('ga360-id');
  if (ga360id == undefined) {
    console.log(
      '%c dodać ga360-id do slidera #' + $(this).data('id'),
      'background: #553311; padding: 3px; color: #ffaaaa'
    );
  } else {
    statElementCollector[ga360id + '-' + $artID] = {
      id: ga360id,
      moduleName: $(this).data('id'),
      viewedCount: 0,
      itemCount: $(this).find('.owl-item:not(.cloned)').length,
    };
  }
  $(this).statVievedSlide = [];
  //pozycje
  $(this)
    .find('.owl-item:not(.cloned)')
    .each(function (n) {
      $(this).attr('idx', n + 1);
    });
  slidePositionVieved[ga360id + '-' + $artID] = {};
});

function ga360Test() {
  var statElementArray = [];

  for (var pos in statElementCollector) {
    statElementArray.push(statElementCollector[pos]);
  }
  // console.log(statElementArray);
}

owl.find('.owl-next, .owl-prev').on('click', function (event) {
  var $owl = $(this).parents('.owl-carousel');
  var $artID = $(this).parents('.__js_stats_wrapper').data('id');
  var $gId = $(this).parents('.owl-carousel').data('id');
  var $acId = $owl.find('.owl-item.center').find('.article__header').text();
  //ga360
  var ga360id = $owl.data('ga360-id');
  var activeItem = $owl.find('.owl-item.center').attr('idx');
  slidePositionVieved[ga360id + '-' + $artID][activeItem] = 1;
  statElementCollector[ga360id + '-' + $artID]['viewedCount'] = Object.keys(
    slidePositionVieved[ga360id + '-' + $artID]
  ).length;

  sendStats('gallery', 'click', $artID + ' / ' + $gId, $acId);
  WP.pwp.customAction({
    ga: {
      event: 'content_interaction',
      eventCategory: ga360id,
      moduleName: $gId,
      eventAction: 'click',
    },
  });
});

// owl.on('resized.owl.carousel', function (event) {
//   console.log('resize2');
// });

owl.on('dragged.owl.carousel', function (event) {
  var $artID = $(this).parents('article').data('id');
  var $gId = $(this).data('id');
  var ga360id = $(this).data('ga360-id');
  var $item = $('.owl-item');
  var $acId = $item.eq(event.item.index).find('.article__header').text();
  slidePositionVieved[ga360id + '-' + $artID][event.item.index] = 1;
  statElementCollector[ga360id + '-' + $artID]['viewedCount'] = Object.keys(
    slidePositionVieved[ga360id + '-' + $artID]
  ).length;
  sendStats('gallery', 'swipe', $artID + ' / ' + $gId, $acId);
  WP.pwp.customAction({
    ga: {
      event: 'content_interaction',
      eventCategory: ga360id,
      moduleName: $gId,
      eventAction: 'swipe',
    },
  });
});

const isPostLogin = document.location.search.indexOf('post_login') > 0;
var viewData = [];

// flaga dla urządzeń z touch:
if ('ontouchstart' in document.documentElement) {
  var deviceTouch = true;
}

// flaga dla scrollowania:
var isScrolligFlag = false;
var idTS;

var lastScrollTop = 0;

window.addEventListener('scroll', function () {
  isScrolligFlag = true;
  if (idTS) {
    clearTimeout(idTS);
  }
  idTS = setTimeout(function () {
    idTS = null;
    isScrolligFlag = false;
  }, 450);
  let st = window.scrollY || document.documentElement.scrollTop;
  if (st > lastScrollTop) {
    $('.header').removeClass('header--visible');
    $('.header__nav').removeClass('header__nav--visible');
    $('.header').addClass('header--hidden');
    $('.header__nav').addClass('header__nav--hidden');
  } else {
    $('.header').addClass('header--visible');
    $('.header__nav').addClass('header__nav--visible');
    $('.header').removeClass('header--hidden');
    $('.header__nav').removeClass('header__nav--hidden');
  }
  lastScrollTop = st <= 0 ? 0 : st;
});

$(document).on('click', '[data-st-click]', function () {
  var T = $(this).data('st-click');
  if (T.length) {
    if (T.value.length) {
      sendStats(T.category, T.action, T.label, T.value);
    } else {
      sendStats(T.category, T.action, T.label);
    }
  }
});

var $sections;

const animateSocials = function () {
  if (document.querySelectorAll('.__js_socialShare').length > 0) {
    document
      .querySelectorAll('.__js_socialShare:not(.__js_socialShare--loaded)')
      .forEach((el) => {
        el.classList.add('__js_socialShare--loaded');
        el.addEventListener('mouseenter', function () {
          if (this.getAttribute('data-category') === 'facebook') {
            el.closest('.socials-items').classList.remove(
              'socials-items-right'
            );
            el.closest('.socials-items').classList.add('socials-items-left');
          } else {
            el.closest('.socials-items').classList.remove('socials-items-left');
            el.closest('.socials-items').classList.add('socials-items-right');
          }
        });
        el.addEventListener('mouseleave', function () {
          el.closest('.socials-items').classList.remove('socials-items-left');
          el.closest('.socials-items').classList.remove('socials-items-right');
        });
      });
  }
};

animateSocials();
