(function () {
  var loadTeasers = function () {
    var teaserFlag = false;

    $(document).on('click', '.btn--more', function () {
      if (
        !teaserFlag &&
        $('.article--load').last().data('more') !== undefined
      ) {
        $.ajax({
          url: $('[data-more]').last().data('more'),
          beforeSend: function () {
            teaserFlag = true;
          },
          error: function () {},
          success: function (html) {
            const pageOffset = window.pageYOffset;
            $(html).insertAfter($('[data-more]').last());
            document
              .querySelectorAll(
                '._js_inViewport, .__js_preload:not(.animated--loaded)'
              )
              .forEach((el) => {
                el.classList.add('animated--loaded');
                observer.observe(el);
              });
            window.scrollTo({
              top: pageOffset,
              left: 0,
              // behavior: 'smooth',
            });

            $sections = $('.__js_stats_wrapper');
            handleFormVoting();
            animateSocials();

            teaserFlag = false;

            if (!$('.article--load').last().data('more')) {
              $('.btn--more').hide();
            }
          },
        });
      }
      setTimeout(function() {  
        $('.outer_background').css('height', $('.outer').height() + $('footer').outerHeight(true) + 'px');
      }, 1000);
    });
  };
  if ($('.article--load').length) {
    loadTeasers();
    setTimeout(function() {  
      $('.outer_background').css('height', $('.outer').height() + $('footer').outerHeight(true) + 'px');
    }, 1000);
  }
})();
