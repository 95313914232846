(()=>{

  //Wersja serwisowa z 1Login
  
  let data = {};

  const postLogin = function() {
    const storageData = storage();
     if (!storageData || storageData.length < 9) {
      console.log('1LPost: Nie udało się odzyskać sesji po powrocie z logowania 1L');
      document.location.href = document.location.pathname;
      return;
    };
  
    data = JSON.parse(storageData);
    if (data.candidate.length<3) {
      console.log('1LPost: Brak kandydata');
      document.location.href = document.location.pathname;
      return;
    };
    sendVote();
    window.localStorage.removeItem('post');
    history.pushState(
      {
        id: document.querySelector('article').id
      },
      data.category,
      /([^?]+)/.exec(document.location.href)[1]
    );
  };

  const storage = function(dataString) {
    if (dataString) {
      window.localStorage.setItem('post', dataString);
    } else {
      return window.localStorage.getItem('post');
    }
  };

  const goToStep2 = function (av, vc, fv, el, eS) {
    if (av) {
      av.classList.remove('article__voting--hidden');
    }
    if (vc) {
      vc.classList.add('voting__container--step2');
    }
    if (fv) {
      fv.classList.add('form--voting--visible');
    }
    if (el) {
      el.style.display = 'none';
    }
    if (eS && !isPostLogin) {
      window.scrollTo({
        top: window.pageYOffset + eS.getBoundingClientRect().top + 10,
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  const sendVote = function() {
    const url = document.querySelector('form').getAttribute('data-url');
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.text())
      .then((html) => {
        if (html.startsWith('h')) {
          data['post_login'] = 1;
          storage(JSON.stringify(data));
          document.location.href = html;
        } else {
          const vC = document.querySelector('.voting__container');
          vC.innerHTML = html;
          window.sessionStorage.clear();
          vC.parentNode.parentNode.classList.remove('article__voting--hidden');
          setTimeout(()=>{
            window.scrollTo({
              top:
                Math.round(window.scrollY) +
                Math.round(vC.getBoundingClientRect().top) -
                170,
              left: 0,
              behavior: 'smooth',
            });
          }, 10);
        }
      })
      .catch((err) => {
        // console.log('Error', err);
      });
  };

  const getUserStatus = function(qs){
    let url = document.querySelector('form');
    if (!url) return;
    url = url.getAttribute('data-status');
    if (!url || url.length < 3) url = '/api/user_status';
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response=>response.json())  
      .then((data) => {
        //data = {"isLogged":false,"gotPrize":null,"prizeStock":true};
        //console.log('API UserStatus: ', data);
        if (
          !!data.prizeStock && //jeżeli są nagrody
          (!data.isLogged || //jest niezalogowany
            !!data.isLogged && !data.gotPrize) //lub zalogowany, ale nie oddał głosu
        ) {  
            document.querySelectorAll(qs).forEach((elm) => {
              elm.style.display = 'flex';
            });
        } else {
          //Formularz 1Login pozostają ukryte
          document.querySelector('form .send-vote:nth-of-type(2)').innerHTML = 'Zagłosuj';

        }
      })
      .catch((err) => {
         //console.log('Error', err);
      });    
  };

  
  const articlesLoaded = document.querySelectorAll(
    '.article--ajax-wrapper:not(.article--loaded)'
  );

  if (articlesLoaded.length) {
    articlesLoaded.forEach((art) => {
      const cursorModifiers = art.querySelectorAll('.profile__item');
      art.classList.add('article--loaded');

      // artykul kategorii, wybor kandydatki
      const votingChoice = art.querySelectorAll('.profile__item[data-candidate]');
      if (votingChoice.length) {
        votingChoice.forEach((el) => {
          el.addEventListener('click', function () {
            const candidateId = this.getAttribute('data-candidate');
            const votingContainer = art.querySelector('.voting__container');
            if (!el.classList.contains('profile__item--selected')) {
              art.querySelectorAll('[data-candidate]').forEach((el) => {
                el.classList.remove('profile__item--selected');
              });
              // WYBÓR KANDYDATKI - KLIKNIĘCIE BUTTONA W SEKCJI NOMINOWANE LUB PROFILU W SEKCJI ODDAJ GŁOS
              art
                .querySelectorAll(`[data-candidate="${candidateId}"]`)
                .forEach((el) => {
                  el.classList.add('profile__item--selected');
                });
              // artykul kategorii, wybor kandydatki w sekcji nominowane, odsloniecie formularza
              if (
                votingContainer.querySelectorAll('.profile__item--selected')
                  .length > 0
              ) {
                art
                  .querySelector('.article__voting')
                  .classList.remove('article__voting--hidden');
                goToStep2(
                  null,
                  votingContainer,
                  votingContainer.querySelector('.form--voting'),
                  null,
                  votingContainer.closest('.article__voting')
                );
              }
            }
          });
        });
      };

      // glosowanie w artykule o danej kandydatce- odsloniecie formularza
      const showBtns = art.querySelectorAll('.show-form');
      if (
        showBtns.length &&
        art.querySelectorAll('.article__voting--hidden').length
      ) {
        showBtns.forEach((el) => {
          el.addEventListener('click', function () {
            goToStep2(
              art.querySelector('.article__voting--hidden'),
              art.querySelector('.voting__container'),
              art.querySelector('.voting__container .form--voting'),
              el,
              art.querySelector('.article__voting')
            );
          });
        });
      }

      const forms = art.querySelectorAll('.form--voting');
      if (forms.length) {
        forms.forEach((form) => {
          const fieldToValidate = form.querySelectorAll('.form-field');

          document.getElementById('agree-1').addEventListener('change', function () {
            validateCheckbox(this);
          });

          const submitBtns = form.querySelectorAll('.send-vote');
          data['form_placement'] = 'site';

          // oddanie glosu - wysylanie danych z formularza
          submitBtns.forEach((btn) => {
            btn.addEventListener('click', function (e) {
              e.preventDefault();
              let formIsValid = true;

              data.candidate = art
                .querySelector('.profile__item--selected')
                .getAttribute('data-candidate');

              data.category = art.getAttribute('data-category-name');
              // walidacja formularza z danymi
              if (btn.classList.contains('send-vote-onelogin')) {
                let cbx = document.getElementById('agree-1');
                
                  if (!validateCheckbox(cbx)) {
                    formIsValid = false;
                  } else {
                    formIsValid = true;
                  }
                
                data.button = 'vote_register';
              } else {
                // tutaj kod bez danych
                fieldToValidate.forEach((el) => {
                  // jak cos ma error z fieldstovalidate to usun error
                  el.closest('fieldset').classList.remove('has-error');
                  el.closest('fieldset').setAttribute('data-error', '');
                  if (el.value.trim().length > 0) {
                    data[el.getAttribute('data-id')] = el.value;
                  }
                });
                data.button = 'vote';
              }

              if (formIsValid && data.candidate) {

                if (btn.classList.contains('send-vote-onelogin')) {
                  data.button = 'onelogin';  
                } else {
                  data.button = 'vote';
                }

                sendVote();

                /* KONIEC FORMULARZA */
              }
            });
          });
        });
      }
    });
  }

  //obsługa postlogin - powrót z logowania 1L
  if (isPostLogin) {
    postLogin();
  } else {
    //sprawdzanie czy spełnione kryteria do wyświetlenia sekcji z nagrodami 1L
    getUserStatus('.send-vote-onelogin, .article__form_sub, .agrees');
  }

})();
document.querySelectorAll('.agrees').forEach(function (el) {
  const span = document.createElement('span');
  el.appendChild(span);
});
