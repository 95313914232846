'use stirct';

const winnersArray = [
    ['najlepszy-serial-2022-roku', 'wielka-woda'],
    ['najlepszy-polski-serial', 'wielka-woda'],
    ['najlepszy-a-polski-a-aktor-aktorka-serialowy-a', 'agnieszka-zulewska'],
    ['najlepszy-serial-do-bingeowania', 'wielka-woda'],
    ['najlepsza-scena-w-serialu', 'zalanie-tunelu-w-wielkiej-wodzie'],
    ['najlepszy-trend-serialowy-na-tiktoku', 'wednesday-taniec-wednesday'],
    ['najlepszy-serial-amazon-prime-video', 'reacher'],
    ['najlepszy-serial-disney', 'andor'],
    ['najlepszy-serial-netfliksa', 'wielka-woda'],
    ['najlepszy-serial-playera', 'szadz'],
    ['najlepszy-serial-hbo-max', 'rod-smoka']
];

function markWinners(winners) {
    winners.forEach(winner => {
        if((window.location.href).includes(winner[0])) {
            const winnerItem = document.querySelector(`.profile__item[data-candidate="${winner[1]}"]`);
            if (winnerItem) winnerItem.classList.add('category_winner');
        }
    });
}

markWinners(winnersArray);
