const validateField = function (el, char) {
  var isValid = el.value.trim().length > char;

  if (!isValid) {
    el.closest('fieldset').classList.add('has-error');
    el.closest('fieldset').setAttribute(
      'data-error',
      'To pole jest obowiązkowe, aby ubiegać się o nagrodę'
    );
  } else {
    el.closest('fieldset').classList.remove('has-error');
    el.closest('fieldset').setAttribute('data-error', '');
  }
  return isValid;
};

const validateCheckbox = function (el) {
  if (!el.checked) {
    el.nextElementSibling.classList.add('has-error');
    el.classList.add('has-error');
  } else {
    el.nextElementSibling.classList.remove('has-error');
    el.classList.remove('has-error');
  }
  return el.checked;
};

const checkEmail = function (emailAddress) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/;
  return re.test(String(emailAddress).toLowerCase());
};

const validateEmail = function (email) {
  var isValid = checkEmail(email.value);

  if (!isValid) {
    email.closest('fieldset').classList.add('has-error');
    email
      .closest('fieldset')
      .setAttribute(
        'data-error',
        'To pole jest obowiązkowe, aby ubiegać się o nagrodę'
      );
  } else {
    email.closest('fieldset').classList.remove('has-error');
    email.closest('fieldset').setAttribute('data-error', '');
  }
  return isValid;
};
