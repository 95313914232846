const cutWords = function (selector, length) {
  if (document.querySelectorAll(selector).length > 0) {
    document.querySelectorAll(selector).forEach((el) => {
      if (el.innerText.length > length) {
        el.innerText = el.innerText.substr(0, length) + '\u2026';
      }
    });
  }
};

cutWords('#jury .profile__text p', 300);
cutWords('.profile__item.item__jury .profile__text p', 300);
