'use strict';

const ambassadors = $('.ambassador');

ambassadors.each(function() {
    $(this).append(`<button class="btn-main">ROZWIŃ WIĘCEJ</button>`);

    $(this).find('button.btn-main').on('click', function() {
        $(this).parent('.ambassador').toggleClass('desc-opened');
        if($(this).parent('.ambassador').hasClass('desc-opened')) {
            $(this).text('ZWIŃ');
        }
        else {
            $(this).text('ROZWIŃ WIĘCEJ');
        }
    });
});
